<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      heading="Driver certificates"
      row-click-path="/tables/driver-certificates"
      endpoint="driver-certificates"
      @create="createDriverCertificate()"
      @edit="data => editDriverCertificate(data)"
      @delete="id => deleteDriverCertificate(id)"
      @restore="id => restoreDriverCertificate(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.title"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                :rules="[v => Boolean(v) || 'Please provide a title']"
                label="Title"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.priority"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  :rules="[v => Boolean(v) || 'Please provide a priority']"
                  type="number"
                  label="Priority"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.description"
                label="Description"
              />
            </v-flex>

            <v-flex
              xs12
              sm6>
              <input
                id="file"
                ref="myFiles"
                type="file"
                class="custom-file-input"
                multiple
                accept="image/*"
                @change="previewFiles">
            </v-flex>
            <v-flex
              xs12
              sm6>
              <div
                v-if="editDialog.data.imageData != null && editDialog.data.imageData.length > 0"
                class="image-preview column-layout justify-center align-center">
                <img
                  :src="editDialog.data.imageData"
                  class="preview">
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'

export default {
  components: { CrudTable },
  mixins: [Request],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Icon', value: 'icon', textValue: 'url', image: true },
        { text: 'Title', value: 'title' },
        { text: 'Priority', value: 'priority' },
        { text: 'Description', value: 'description', textValue: 'description' },
        { text: '', value: null }
      ],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      files: {},
      imageData: null
    }
  },
  methods: {
    previewFiles (event) {
      this.files = this.$refs.myFiles.files

      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.editDialog.data.imageData = e.target.result
          this.editDialog.data.imageName = input.files[0].name
          this.$forceUpdate()
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    async saveDriverCertificateIcon (reference, file) {
      return new Promise((resolve) => {
        let body = {
          data: {
            'name': this.editDialog.data.imageName,
            'content': file,
            'type': 'driver-certificate-icon',
            'reference': reference
          }
        }
        this.request('POST', '/files', body, ({ data }) => {
          resolve(1)
        })
      })
    },
    createDriverCertificate () {
      this.editDialog.display = true
      this.editDialog.data = { }

      this.editDialog.type = 'create'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            'title': this.editDialog.data.title,
            'priority': this.editDialog.data.priority,
            'description': this.editDialog.data.description
          }
        }

        this.request('POST', `/driver-certificates`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Driver certificate is created'
          })

          if (this.editDialog.data.imageData != null) {
            let imageData = this.editDialog.data.imageData.replace(/^data:image.+;base64,/, '')

            this.saveDriverCertificateIcon(data.id, imageData).then(() => {
              this.editDialog.display = false
              this.$refs.crudTable.requestData()
            })
          } else {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
          }
        })
      }
    },
    deleteDriverCertificate (id) {
      this.request('DELETE', `/driver-certificates/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver certificate is deleted'
        })
      })
    },
    restoreDriverCertificate (id) {
      this.request('PATCH', `/trash/un-delete/driver-certificate/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver certificate is restored'
        })
      })
    },
    editDriverCertificate (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'

      this.editDialog.data.imageData = this.editDialog.data.icon != null ? this.editDialog.data.icon.url : null

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            'title': this.editDialog.data.title,
            'priority': this.editDialog.data.priority,
            'description': this.editDialog.data.description
          }
        }

        this.request('PATCH', `/driver-certificates/${data.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Driver certificate is updated'
          })

          if (this.editDialog.data.imageData != null) {
            let imageData = this.editDialog.data.imageData.replace(/^data:image.+;base64,/, '')

            this.saveDriverCertificateIcon(data.id, imageData).then(() => {
              this.editDialog.display = false
              this.$refs.crudTable.requestData()
            })
          } else {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
